import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { DateInputComponent } from './date-input/date-input.component';
import { DatePagerComponent } from './date-pager/date-pager.component';
import { DateRangePickerComponent } from './date-range/date-range-picker.component';
import { PeriodDatePagerComponent } from './period-date-pager/period-date-pager.component';
import { DatePipe } from './pipes/date.pipe';
import { SingleDatePagerComponent } from './single-date-pager/single-date-pager.component';

const components = [SingleDatePagerComponent, PeriodDatePagerComponent];

const standaloneComponents = [DateInputComponent, DateRangePickerComponent, DatePagerComponent];

@NgModule({
  imports: [
    CommonModule,
    OverlayPanelModule,
    CalendarModule,
    FormsModule,
    IconComponent,
    DatePipe,
    ...standaloneComponents,
  ],
  declarations: components,
  exports: [...components, ...standaloneComponents],
})
export class SbCalendarModule {
  public static forRoot(): ModuleWithProviders<SbCalendarModule> {
    return {
      ngModule: SbCalendarModule,
    };
  }
}
