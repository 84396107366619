import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import isArray from 'lodash-es/isArray';
import { format, isValid, startOfDay } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { getPrimeNGDateFormat } from '../../../../+authenticated/shared/locale/locale.helper';
import { parseDate } from '../../../date.helper';
import { DefaultDateFormat } from '../sb-calendar.model';
import { OverlayPanel } from 'primeng/overlaypanel';

export const DATE_RANGE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PeriodDatePagerComponent),
  multi: true,
};

@Component({
  selector: 'period-date-pager',
  templateUrl: 'period-date-pager.component.html',
  styleUrls: ['period-date-pager.component.scss'],
  providers: [DATE_RANGE_PICKER_CONTROL_VALUE_ACCESSOR],
})
export class PeriodDatePagerComponent implements ControlValueAccessor, OnInit {
  private _minDate: Date;

  private _maxDate: Date;

  private _placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  @Input()
  public fullWidth = false;
  @Input()
  public disabled: boolean;
  @Input()
  public dark = false;
  @Input()
  public extended: boolean;
  @Input()
  public label: string;
  @Input()
  public icon: string;

  public dates: Date[];
  public value: string[];

  // internal functions to call when ControlValueAccessor
  // gets called
  public onTouched: () => void;
  public onModelChange: (dates: string[]) => void;

  public dateFormat: string;
  @Input()
  public set minDate(value) {
    if (!value) {
      this._minDate = null;
      return;
    }

    this._minDate = startOfDay(parseDate(value));
  }

  public get minDate(): Date {
    return this._minDate;
  }

  @Input()
  public set maxDate(value) {
    if (!value) {
      this._maxDate = null;
      return;
    }
    this._maxDate = startOfDay(parseDate(value));
  }

  public get maxDate() {
    return this._maxDate;
  }

  @Input()
  public set placement(value) {
    if (!value) {
      this._placement = 'bottom';
      return;
    }
    this._placement = value;
  }

  public get placement() {
    return this._placement;
  }

  @ViewChild(OverlayPanel)
  public overlayPanel: OverlayPanel;

  public constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    this.dateFormat = getPrimeNGDateFormat(this.translateService.currentLang) ?? DefaultDateFormat;
  }

  // Change via typing
  public onChangeInput(dates: Date[]) {
    if (dates === null || (isArray(dates) && !isValid(dates[0]) && !isValid(dates[1]))) {
      this.value = ['', ''];
      return;
    }

    this.value = dates.map((date) => (isValid(parseDate(date)) ? format(parseDate(date), 'yyyy-MM-dd') : ''));
    this.dates = dates;
    if (dates.every((date) => isValid(date))) {
      this.overlayPanel.hide();
      this.onChange();
    }
  }

  private onChange() {
    this.onModelChange(this.value);
  }

  public registerOnChange(fn: (dates: string[]) => void) {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // writes the value to the local component
  // that binds to the "value"
  public writeValue(value: string[]) {
    if (!value || (isArray(value) && !value[0] && !value[1])) {
      return;
    }

    this.value = value;
    this.dates = value.map((dateVal) => parseDate(dateVal));
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
