import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { startOfDay } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { format, parseDate } from '../../../date.helper';
import { DefaultDateFormat } from '../sb-calendar.model';
import { getPrimeNGDateFormat } from '../../../../+authenticated/shared/locale/locale.helper';
import { OverlayPanel } from 'primeng/overlaypanel';

export const DATE_RANGE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SingleDatePagerComponent),
  multi: true,
};

@Component({
  selector: 'single-date-pager',
  templateUrl: 'single-date-pager.component.html',
  styleUrls: ['single-date-pager.component.scss'],
  providers: [DATE_RANGE_PICKER_CONTROL_VALUE_ACCESSOR],
})
export class SingleDatePagerComponent implements ControlValueAccessor, OnInit {
  private _minDate: Date;

  private _maxDate: Date;

  private _placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  @Input()
  public fullWidth = false;
  @Input()
  public disabled: boolean;
  @Input()
  public dark = false;

  public date: Date;
  private value: string;

  // internal functions to call when ControlValueAccessor
  // gets called
  public onTouched: () => void;
  public onModelChange: (date: string) => void;

  public dateFormat: string;
  @Input()
  public set minDate(value) {
    if (!value) {
      this._minDate = null;
      return;
    }

    this._minDate = startOfDay(parseDate(value));
  }

  public get minDate(): Date {
    return this._minDate;
  }

  @Input()
  public set maxDate(value) {
    if (!value) {
      this._maxDate = null;
      return;
    }
    this._maxDate = startOfDay(parseDate(value));
  }

  public get maxDate() {
    return this._maxDate;
  }

  @Input()
  public set placement(value) {
    if (!value) {
      this._placement = 'bottom';
      return;
    }
    this._placement = value;
  }

  public get placement() {
    return this._placement;
  }

  @ViewChild(OverlayPanel)
  public overlayPanel: OverlayPanel;

  public constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    this.dateFormat = getPrimeNGDateFormat(this.translateService.currentLang) ?? DefaultDateFormat;
  }

  // Change via typing
  public onChangeInput(date: any) {
    this.overlayPanel.hide();
    this.value = this.dateToValue(date);
    this.date = date;
    this.onChange();
  }

  public dateToValue(date: Date | null | ''): string {
    if (!date || (typeof date === 'string' && date === '')) {
      return '';
    }

    return format(parseDate(date), 'yyyy-MM-dd');
  }

  private onChange() {
    this.onModelChange(this.value);
  }

  public registerOnChange(fn: (date: string) => void) {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // writes the value to the local component
  // that binds to the "value"
  public writeValue(value: string) {
    if (value === null) {
      return;
    }

    this.value = value;

    if (value === '') {
      this.date = undefined;
    } else {
      this.date = parseDate(value);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
