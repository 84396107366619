<div class="date-pager btn-group">
  <button
    (click)="overlayPanel.toggle($event)"
    type="button"
    class="date-pager__item btn btn-default btn-large report-single-date flex h-[34px] items-center"
  >
    {{ date | date: 'd MMM' }}
  </button>

  <p-overlayPanel #overlayPanel>
    <ng-template pTemplate>
      <p-calendar
        [clearButtonStyleClass]="'sb-calendar-hide-clear'"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [(ngModel)]="date"
        [disabled]="disabled"
        [showButtonBar]="true"
        [firstDayOfWeek]="1"
        [showClear]="false"
        [showWeek]="true"
        [inline]="true"
        [dateFormat]="dateFormat"
        (onSelect)="onChangeInput(date)"
      ></p-calendar>
    </ng-template>
  </p-overlayPanel>
</div>
