<div class="date-pager btn-group">
  <ng-container *ngIf="extended">
    <div class="date-pager__item flex h-[34px] items-center lg:hidden">
      <icon [svgIcon]="icon"></icon>
    </div>
  </ng-container>
  <button
    (click)="overlayPanel.toggle($event)"
    type="button"
    class="date-pager__item btn btn-default btn-large report-period-date flex h-[34px] items-center"
  >
    <ng-container *ngIf="extended">
      <span class="hidden lg:block">{{ label }}:&nbsp;</span>
    </ng-container>
    <span>{{ dates[0] | date: 'd MMM' }} - {{ dates[1] | date: 'd MMM' }}</span>
  </button>

  <p-overlayPanel #overlayPanel>
    <ng-template pTemplate>
      <p-calendar
        [clearButtonStyleClass]="'sb-calendar-hide-clear'"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [(ngModel)]="dates"
        [disabled]="disabled"
        [showButtonBar]="true"
        [firstDayOfWeek]="1"
        [showClear]="false"
        [numberOfMonths]="2"
        [showWeek]="true"
        [inline]="true"
        [selectionMode]="'range'"
        [dateFormat]="dateFormat"
        (onSelect)="onChangeInput(dates)"
      ></p-calendar>
    </ng-template>
  </p-overlayPanel>
</div>
